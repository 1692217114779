<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-date
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        v-model="formData.deliveredAt"
        isNull
        label="วันที่จัดส่ง">
      </sgv-input-date>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-4"
        label="เลขที่ใบเสร็จ/ใบกำกับภาษี"
        v-model="formData.receiptRef"
        :validations="[
          {text: 'required!', value: v.formData.receiptRef.$dirty && v.formData.receiptRef.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="หมายเหตุ"
        v-model="formData.remark">
      </sgv-input-text>
    </div>

    <div class="form-row" v-if="method !== 'add'">
      <sgv-input-text
        disabled
        class="col-12"
        label="เจ้าหนี้"
        :value="contactTxt">
      </sgv-input-text>
    </div>

    <div class="form-row" v-if="method === 'add'">
      <DetailFormContactInput
        :templateType="templateType"
        :docType="docType"
        :label="`เจ้าหนี้ ${formData.contactId && !isAddressValid ? '--- ไม่พบที่อยู่' : ''}`"
        placeholder="เลือกเจ้าหนี้..."
        class="col-12"
        select="id"
        v-model="formData.contactId"
        @change="contact = $event"
        :validations="[
          {text: 'required!', value: v.formData.contactId.$dirty && v.formData.contactId.$error}
        ]">
        <template slot-scope="option">
          {{option.contact.code}} ({{option.contact.name}})
        </template>
      </DetailFormContactInput>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DetailFormContactInput from './DetailFormContactInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  data () {
    return {
      contact: null
    }
  },
  computed: {
    isAddressValid () {
      return this.contact?.addresses.find(v => {
        return v.type === 'main' && v.isDefault
      })
    },
    contactTxt () {
      if (!this.formData.contact) return ''
      const contact = this.formData.contact
      const branch = contact.branch && contact.branch !== 'สำนักงานใหญ่' ? `สาขา: ${contact.branch} --` : ''
      return `${branch} ${contact.code} (${contact.name})`
    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailFormContactInput,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
