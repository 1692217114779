import gql from 'graphql-tag'


export const LIST_KPI = (templateType) => gql`query LIST_KPI ($docType: String!, $docId: Int!) {
  doc: detailDoc${templateType} (docType: $docType, docId: $docId) {
    id
    kpiTemplate {
      id code name type
      children {
        id code name
        children {
          id code name formType
          actualPoint minPoint maxPoint
          choices {
            text value
          }
        }
      }
    }
    kpis {
      id type code name remark
      actualPoint minPoint maxPoint
    }
  }
}`

export const UPDATE_KPI = (templateType) => gql`mutation UPDATE_KPI ($docType: String!, $docId: Int!, $kpis: [Doc${templateType}KpiInput]!) {
  updateKpi: updateDoc${templateType}Kpi (docType: $docType, docId: $docId, kpis: $kpis) {
    id type code name remark
    actualPoint minPoint maxPoint
  }
}`


export const LIST_REPORT_KPI = (templateType) => gql`query LIST_REPORT_KPI ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $contactId: Int) {
  items: listDoc${templateType}ReportKpi (docType: $docType, startDate: $startDate, endDate: $endDate, contactId: $contactId) {
    id type code name
    kpis {
      name percent remarks
    }
  }
}`

export const LIST_REPORT_KPI_CONTACT = (templateType) => gql`query LIST_REPORT_KPI_CONTACT ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}ReportKpiContact (docType: $docType, q: $q) {
    id type code name branch
  }
}`
