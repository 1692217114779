<template>
  <div class="row mt-3">
    <template>
      <div class="col-12">
        <div class="form-row">
          <sgv-input-radio
            class="col-12"
            inline
            :options="types"
            select="text"
            v-model="selected">
            <template slot-scope="option">
              {{option.text}}
            </template>
          </sgv-input-radio>
        </div>
      </div>

      <div
        class="col-12"
        v-for="type in types"
        :key="type.text">
        <component
          v-if="selected === type.text"
          :is="type.value"
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :formData="formData"
          :url="type.url"
          :filename="type.filename">
        </component>
      </div>
    </template>
  </div>
</template>

<script>
import DetailPrintPdf from '@/views/doc_core/components/PrintPdf'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    isPayment: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    types () {
      const arr = [
        {
          text: 'ใบสั่งซื้อ',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print/po`,
          filename: `${this.formData.code}-po.pdf`
        }
      ]

      if (this.formData.closedAt) {
        arr.push({
          text: 'ใบแนบบัญชี',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print/cover`,
          filename: `${this.formData.code}-cover.pdf`
        })
        arr.push({
          text: 'เอกสารหัก ณ ที่จ่าย',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print/withholding`,
          filename: `${this.formData.code}-withholding.pdf`
        })
      }

      if (this.formData.closedAt && this.isPayment) {
        arr.push({
          text: 'ใบสำคัญรับเงิน',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print/receipt`,
          filename: `${this.formData.code}-receipt.pdf`
        })
      }

      return arr
    }
  },
  created () {
    if (this.formData.closedAt) {
      this.selected = 'ใบแนบบัญชี'
    } else {
      this.selected = 'ใบสั่งซื้อ'
    }
  },
  components: {
    DetailPrintPdf
  }
}
</script>

<style lang="css" scoped>
</style>
