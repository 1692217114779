<template>
  <span class="">
    <span
      v-if="configs.length > 0"
      class="pointer mr-1"
      @click="toggle">
      <slot></slot>
    </span>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12 pt-3">
          <div class="form-row">
            <sgv-input-select
              class="col-8"
              label="บัญชีงานระหว่างทำ"
              placeholder="เลือกบัญชี..."
              :options="configs"
              v-model="formData.docConfigId"
              select="id">
              <template slot-scope="option">
                {{option.name}}
              </template>
            </sgv-input-select>

            <sgv-input-number
              class="col-4"
              label="จำนวน"
              v-model="formData.qty">
            </sgv-input-number>
          </div>

          <button
            type="button"
            class="btn btn-primary my-3"
            @click="emitData">
            ตกลง
          </button>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { LIST_ORDER_WORK_IN_PROGRESS } from './graph'

export default {
  name: 'DetailOrderItemInputWorkInProgress',
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      configs: [],
      formData: {
        docConfigId: null,
        qty: 0
      }
    }
  },
  apollo: {
    configs: {
      query () {
        return LIST_ORDER_WORK_IN_PROGRESS(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {
            order: 'name'
          }
        }
      }
    }
  },
  methods: {
    toggle () {
      this.showModal = true
    },
    emitData () {
      const config = this.configs.find(x => x.id === this.formData.docConfigId)
      const obj = {
        name: '',
        id: null,
        qty: 0
      }

      if (config) {
        obj.name = config.name,
        obj.id = config.id,
        obj.qty = this.formData.qty
      }

      this.$emit('update', obj)
      this.showModal = false
    }
  },
}
</script>

<style lang="css">
</style>
