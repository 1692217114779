<template>
  <tr>
    <td class="no-wrap">
      <fa
        v-if="isDestroyable"
        icon="trash"
        class="text-danger pointer mr-1"
        @click="destroyOrder(item.id)">
      </fa>

      <span @click="emitSelected(item.id)" class="pointer">
        {{item.id}}
      </span>
    </td>
    <td class="pre-line">
      {{item.name}}
      <small>
        <div v-if="item.inventory" class="text-primary">
          {{item.inventory.code}} ({{item.inventory.name}})
        </div>
        <div v-if="withholdingType">
          <div class="text-info">
            {{item.withholdingTopic}}.
            {{item.withholdingName}}
            ({{item.withholdingRate}}%)
            ({{item.withholdingPrice}})
          </div>
          <div class="text-info">
            {{withholdingType.text}}
          </div>
          <div v-if="item.withholdingRemark" class="text-danger">
            หมายเหตุ: {{item.withholdingRemark}}
          </div>
        </div>
      </small>
    </td>
    <td>
      <span v-if="['item', 'service'].includes(item.type)">
        <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
          {{item.qty | comma}}
        </span>
        <span>{{item.unit.name}}</span>
        <span v-if="item.unitRatio !== 1" class="text-info">
          ({{item.qty * item.unitRatio | comma}})
        </span>
      </span>
      <span v-else>
        -
      </span>
    </td>
    <td class="text-right">
      <span v-if="item.price > 0">{{item.price | comma}}</span>
      <span v-else>-</span>
      <div v-if="item.discount" class="text-primary">
        <small>{{item.discount}} ({{item.discountPrice}})</small>
      </div>
    </td>
    <td
      class="text-right"
      :class="{'text-danger': item.totalPrice < 0}">
      {{item.totalPrice | comma}}
    </td>
  </tr>
</template>

<script>
import { DESTROY_ORDER } from './graph'
import round from 'lodash/round'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isDestroyable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      withholdingTypes: [
        {text: 'หักภาษี ณ ที่จ่าย', value: 1},
        {text: 'ออกภาษีให้ครั้งเดียว', value: 2},
        {text: 'ออกให้ตลอดไป', value: 3},
        {text: 'อื่นๆ', value: 4},
      ]
    }
  },
  computed: {
    isComplete () {
      const curAmount = this.item.children.reduce((t,v) => t += +v.totalPrice, 0)
      return round(this.item.totalPrice - curAmount, 2) === 0
    },
    withholdingType () {
      return this.withholdingTypes.find(v => v.value === this.item.withholdingType)
    }
  },
  methods: {
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitSelected (id) {
      this.$emit('selected', id)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
