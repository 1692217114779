import gql from 'graphql-tag'


export const LIST_REPORT_EXPENSE = (templateType) => gql`query LIST_REPORT_EXPENSE ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $categoryId: Int) {
  items: listDoc${templateType}ReportExpense (docType: $docType, startDate: $startDate, endDate: $endDate, categoryId: $categoryId)
}`

export const SYNC_REPORT_EXPENSE = (templateType) => gql`mutation SYNC_REPORT_EXPENSE ($docType: String!, $month: String!) {
  result: syncDoc${templateType}ReportExpense (docType: $docType, month: $month)
}`


export const LIST_REPORT_PURCHASE = (templateType) => gql`query LIST_REPORT_PURCHASE ($docType: String!, $filter: Doc${templateType}ReportPurchaseFilterInput!, $options: Doc${templateType}ReportPurchaseOptionsInput) {
  report: listDoc${templateType}ReportPurchase (docType: $docType, filter: $filter, options: $options) {
    summary
    summaryInventory
    doc
    docInventory
  }
}`
