<template>
  <div class="form-row">
    <DetailOrderItemInputItem
      ref="dropdownInventory"
      placeholder="สินค้า..."
      class="col-12 col-md-5"
      select="id"
      :docType="docType"
      :templateType="templateType"
      :contactId="contactId"
      @change="recentPurchase"
      @keyup.enter.native="$refs.qty.setFocus()"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">

      <span slot="label">
        สินค้า
        <DetailOrderItemInputWorkInProgress
          title="งานระหว่างทำ"
          :templateType="templateType"
          :docType="docType"
          @update="setWorkInProgress">
          <span class="px-2 text-info pointer">
            <fa icon="columns"></fa>
            <span v-if="formData.workInProgressName">
              {{formData.workInProgressName}} -> {{formData.workInProgressQty}}
            </span>
          </span>
        </DetailOrderItemInputWorkInProgress>
      </span>
    </DetailOrderItemInputItem>

    <DetailOrderItemInputUnit
      label="หน่วย"
      class="col-4 col-md-2"
      select="id"
      v-model="formData.unitId"
      :options="units"
      :validations="[
        {text: 'required!', value: $v.formData.unitId.$dirty && $v.formData.unitId.$error}
      ]">
    </DetailOrderItemInputUnit>

    <sgv-input-text
      ref="qty"
      class="col"
      placeholder="จำนวน ราคา [ส่วนลด | 3%]"
      v-model="formData.price"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
      <label slot="label">
        ราคา:
        <span class="pointer" @click="isVatIncluded = !isVatIncluded">
          <span v-if="isVatIncluded" class="text-warning">รวมภาษี</span>
          <span v-else>ไม่รวมภาษี</span>
        </span>
      </label>
    </sgv-input-text>

    <div class="col-auto form-group align-self-end">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import round from 'lodash/round'
import { required } from 'vuelidate/lib/validators'
import DetailOrderItemInputItem from './DetailOrderItemInputItem.vue'
import DetailOrderItemInputWorkInProgress from './DetailOrderItemInputWorkInProgress.vue'
import DetailOrderItemInputUnit from './DetailOrderItemInputUnit.vue'
import {
  CREATE_ORDER_ITEM,
  DETAIL_ORDER_RECENT
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      formType: 'item',
      formData: {
        docConfigId: null,
        unitId: null,
        price: '',
        workInProgressId: null,
        workInProgressName: '',
        workInProgressQty: 0
      },
      units: [],
      isVatIncluded: false
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      unitId: { required },
      price: { required },
    }
  },
  methods: {
    setWorkInProgress (payload) {
      this.formData.workInProgressId = payload.id
      this.formData.workInProgressName = payload.name
      this.formData.workInProgressQty = payload.qty
    },
    serializeInput (input) {
      try {
        const obj = {
          docConfigId: input.docConfigId,
          unitId: input.unitId,
          workInProgressId: input.workInProgressId,
          workInProgressQty: input.workInProgressQty
        }
        const arr = input.price.trim().split(' ')
        if (arr.length === 1) {
          obj.qty = 1
          obj.price = Number(arr[0])
          obj.discount = ''
        } else if (arr.length === 2) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
          obj.discount = ''
        } else if (arr.length === 3) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
          obj.discount = arr[2]
        }

        if (this.isVatIncluded) {
          const vatConfig = this.configs.find(cf => cf.type === 'vatPrice' && cf.isDefault)
          const vatPrice = vatConfig?.options?.vatPrice || ''

          if (vatPrice.indexOf('%') !== -1) {
            const vatRate = vatPrice.replace('%', '')
            obj.price = round(-1 * Math.abs(obj.price) / (1 + +vatRate / 100), 2)
          } else {
            this.$toasted.global.error('ไม่พบการตั้งค่าภาษี')
            return
          }
        }

        return obj

      } catch (err) {
        this.$toasted.global.error('กรอกข้อมูลไม่ถูกต้อง')
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      if (!input) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.setFocus()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.unitId = null
      this.formData.price = ''
      this.formData.workInProgressId = null
      this.formData.workInProgressName = ''
      this.formData.workInProgressQty = 0
      this.$v.formData.$reset()
    },
    recentPurchase (config) {
      if (!config) return

      const properties = config.inventory?.properties?.map(v => v.unit) || []
      this.units = [
        {id: config.inventory.unit.id, name: config.inventory.unit.name},
        ...properties
      ]

      this.$apollo.query({
        query: DETAIL_ORDER_RECENT(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          inventoryId: config.inventoryId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const {qty, price, discount, unitId} = res.data.item
        this.formData.price = `${qty} ${price} ${discount || ''}`
        this.formData.unitId = unitId
      })
      .catch(() => {
        this.formData.unitId = config.inventory.unitId
      })
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.price = ''
          this.formData.unitId = null
          this.formData.workInProgressId = null
          this.formData.workInProgressName = ''
          this.formData.workInProgressQty = 0
          this.units = []
          this.$v.formData.$reset()
        } else {
          this.formData.workInProgressId = null
          this.formData.workInProgressName = ''
          this.formData.workInProgressQty = 0
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderItemInputItem,
    DetailOrderItemInputUnit,
    DetailOrderItemInputWorkInProgress,
  }
}
</script>

<style lang="css" scoped>
</style>
