<template>
  <div v-if="items.length > 0">
    <button
      v-if="!isEdit"
      type="button"
      class="btn btn-warning mb-3"
      @click="isEdit = true">
      แก้ไขการประเมิน
    </button>

    <div v-if="isEdit" class="mb-3">
      <button
        type="button"
        class="btn btn-warning"
        @click="updateData">
        อัพเดท
      </button>

      <button
        type="button"
        class="btn btn-danger ml-2"
        @click="isEdit = false">
        ยกเลิก
      </button>
    </div>

    <div
      class="mb-3"
      v-for="(section, i) in items"
      :key="section.code">

      <p class="text-success">{{i+1}}. {{section.name}}</p>
      <ul class="list-unstyled">
        <li
          class="px-3 pb-1"
          v-for="(item, index) in section.children"
          :key="item.code">

          <div class="form-row" v-if="item.formType === 'choice'">
            <sgv-input-radio
              class="col-12 mb-2"
              :noClick="!isEdit"
              :options="item.choices"
              select="value"
              inline
              v-model="item.actualPoint">
              <div slot="label">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}</span>
                {{item.name}}
                <span class="text-primary">
                  {{getScore(item)}}
                </span>
              </div>
              <template slot-scope="option">
                {{option.text}}
              </template>
            </sgv-input-radio>

            <sgv-input-text
              v-show="item.remark || isEdit"
              :disabled="!isEdit"
              placeholder="หมายเหตุ (ถ้ามี)"
              v-model="item.remark"
              class="col-12">
            </sgv-input-text>
          </div>

          <div class="form-row" v-if="item.formType === 'between'">
            <sgv-input-number
              class="col-12"
              minus
              :disabled="!isEdit"
              v-model="item.actualPoint">
              <div slot="label" class="pb-2">
                <span class="mr-1 text-secondary">{{i+1}}.{{index+1}}.</span>
                {{item.minPoint | comma(-1)}}
                <fa icon="less-than-equal" class="mx-1"></fa>
                {{item.name}}
                <fa icon="less-than-equal" class="ml-1"></fa>
                {{item.maxPoint | comma(-1)}}
              </div>
            </sgv-input-number>

            <sgv-input-text
              v-show="item.remark || isEdit"
              :disabled="!isEdit"
              placeholder="หมายเหตุ (ถ้ามี)"
              v-model="item.remark"
              class="col-12">
            </sgv-input-text>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { LIST_KPI, UPDATE_KPI } from './graph'
import round from 'lodash/round'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      kpiTemplate: null,
      items: [],
      isEdit: false
    }
  },
  apollo: {
    doc: {
      query () {
        return LIST_KPI(this.templateType)
      },
      fetchPolicy: 'network-only',
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      skip () {
        return this.docId === 0
      },
      result (res) {
        if (res.data.doc) {
          this.kpis = this.setFormData(res.data.doc)
        }
      }
    }
  },
  methods: {
    getScore (item) {
      if (item.maxPoint === 0) return ''
      return `${round(item.actualPoint * 100 / (item.maxPoint - item.minPoint))}%`
    },
    updateData () {
      const kpis = this.items
      .flatMap(v => v.children)
      .map(v => {
        return {
          code: v.code,
          actualPoint: v.actualPoint,
          remark: v.remark
        }
      })

      this.$apollo.mutate({
        mutation: UPDATE_KPI(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          kpis
        }
      })
      .then(() => {
        this.isEdit = false
        this.$apollo.queries.doc.refetch()
        this.$toasted.global.success("อัพเดทแบบประเมิน")
      })
      .catch(this.$toasted.global.error)
    },
    setFormData ({kpiTemplate, kpis}) {
      if (!kpiTemplate) {
        this.kpiTemplate = null
        this.items = []
        return
      }

      this.kpiTemplate = kpiTemplate
      this.items = kpiTemplate.children.map(section => {
        const obj = {...section}
        obj.children = obj.children.map(v => {
          const found = kpis.find(x => x.code === v.code)
          const z = {...v}
          delete z.id

          if (found) {
            z.id = found.id
            z.actualPoint = found.actualPoint
            z.remark = found.remark
          }
          return z
        })
        return obj
      })
    }
  },
}
</script>

<style lang="css">
</style>
